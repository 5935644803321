
.referNewMembers{
  margin-top: 52px;
  padding-left: 10%;
  @media screen and (max-width: 1024px){
    padding-left: 0;
  }

  & > h1{
    font-family: 'DBAdmanX', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 1px;
  }

  & > span{
    font-family: 'DBAdmanX-UL', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 1px;
  }

  & > p{
    font-family: 'DBAdmanX-UL', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #262C31;
    margin-top: 24px;
  }

  .giftsList{
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    @media screen and (max-width: 768px){
      flex-direction: column;
    }

    &__item{
      width: 115px;
      @media screen and (max-width: 768px){
        display: flex;
        width: 100%;
        &:not(:first-child){
          margin-top: 32px;
        }
      }
    }

    &__title{
      @media screen and (max-width: 768px){
        width: 50%;
      }
    }

    & > div:not(:last-child){
      margin-right: 62px;
    }

    h4{
      color: #399DF3;
      font-family: 'DBAdmanX', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 1px;
    }

    span{
      font-family: 'DBAdmanX-UL', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1px;
      color: #262C31;
    }

    p{
      font-family: 'DBAdmanX', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 1px;
      color: #262C31;
      margin-top: 10px;
      padding-top: 7px;
      border-top: 1px solid #ECECEC;
      @media screen and (max-width: 768px){
        border-top: none;
        border-left: 1px solid #ECECEC;
        padding-left: 24px;
      }
    }
  }

  .conditions{
    margin-top: 64px;

    h4{
      font-family: 'DBAdmanX', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 1px;
      color: #262C31;
    }
    & > p{
      font-family: 'DBAdmanX-UL', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1px;
    }

    & p:last-of-type {
      font-weight: 600;
    }

    & > ul, & > ol{
      li{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
      }
    }

    &_points {
      margin-top: 24px;
      li {
        font-family: 'DBAdmanX-UL', sans-serif;
        line-height: 24px;
        letter-spacing: 1px;
        &:before{
          content: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6663 1L5.49967 10.1667L1.33301 6' stroke='%23399DF3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          margin-right: 15px;
        }
      }
    }

    &_notes {
      li {
        margin-left: 12px;
        font-family: 'DBAdmanX', sans-serif;
      }
    }
  }
}
