.languageDropdown{
  position: absolute;
  right: 20px;
  min-width: 60px;

  & > button{
    background-color: transparent;
    border: none;
    font-family: 'DBAdmanX', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    color: #303133;
    cursor: pointer;
    letter-spacing: 0.05em;

    & .arrow{
      margin: 0 0 2px 10px;
      height: 8px;
      width: 13px;
      &_active{
        transform: rotateZ(180deg);
      }
    }
  }

  ul{
    border: 1px solid rgb(237,237,237);
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: white;
    margin-top: 8px;
  }

  li > button{
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-family: 'DBAdmanX', sans-serif;
    width: 58px;
    text-align: left;
    padding: 6px;
    cursor: pointer;
    letter-spacing: 0.05em;

    &:hover{
      background-color: #5897fb;
      color: white;
    }
    &:active{
      background-color: gray;
    }
  }
}

.listItem{
  &_active{
    background-color: #ddd;
  }
}
