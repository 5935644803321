.formsWrapper{
  margin-top: 52px;
  padding-right: 10%;

  @media screen and (max-width: 1024px){
    padding-right: 0;
  }
  @media screen and (max-width: 768px){
    margin-top: 32px;
  }

  & h1:not(:first-child){
    margin-top: 30px;
  }

  & h1{
    font-family: 'DBAdmanX', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 1px;
  }

  & label{
    font-family: 'DBAdmanX', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #303133;
    margin-bottom: 10px;
  }

  .form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 48px;
    @media screen and (max-width: 768px){
      margin-top: 32px;
      gap: 10px;
    }

    & label span{
      color: red;
    }

    & h1{
      grid-column-start: 1;
      grid-column-end: 3;
      margin-bottom: 48px;
      @media screen and (max-width: 768px){
        margin-top: 0;
        margin-bottom: 20px;
      }
    }

    & .inputBlock:nth-child(3) , & .inputBlock:nth-child(9),{
      grid-column-start: 1;
      grid-column-end: 3;
    }

    & .inputBlock:nth-child(4), & .inputBlock:nth-child(5), & .inputBlock:nth-child(10), & .inputBlock:nth-child(11){
      @media screen and (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    & .inputBlock{
      display: flex;
      flex-direction: column;
      min-width: 140px;
      height: 102px;
    }

    & input{
      background-color: #F7F7F7;
      border: 1px solid #ECECEC;
      border-radius: 8px;
      height: 56px;
      padding: 18px 16px;
      font-family: 'DBAdmanX', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #303133;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    & .confirmButton{
      padding: 16px 24px;
      text-transform: uppercase;
      background-color: #399DF3;
      opacity: 0.3;
      color: white;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      margin: 0px 0 100px 0;
      &_active{
        opacity: 1;
      }

      @media screen and (max-width: 530px){
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    & .error{
      color: red;
    }
  }
}


