@import "./fonts.module";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul{
  list-style: none;
}

body{
  background-image: url("../assets/images/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  font-family: DBAdmanX, sans-serif;
}

.mainContainer{
  height: 100vh;

  &__content{
    min-height: calc(100% - 56px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
    @media screen and (max-width: 1024px){
      grid-template-columns: 1fr;
      padding: 0 20px;
      gap: 0;
    }
  }
}
